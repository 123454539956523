import React from 'react';

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from 'components/layout/layoutMain';
import LayoutPanel from 'components/layout/layoutPanel';

function BusinessUpdateFaq() {

	return (
		<>
            <SEO title="Kleenheat Business Update" />
            <Header />
            <LayoutMain>

            	<LayoutPanel background="mer-bg--primary-cyan"                 
			                 padding="mer-py-lg"
			                 theme="mer-theme--cyan">
			        <div className="container">
                        <div className="row mer-panel justify-content-around align-items-middle">
                            <div className="col-12 mer-text--align-center mer-text--size-lg">
                                <h1 className="my-0">Kleenheat Business Update</h1>
                            </div>
                        </div>
                    </div>
			    </LayoutPanel>

                <LayoutPanel background="mer-bg--ui-light"      
                             border="mer-border--bottom"           
			                 padding="mer-py-de">
                    <div className="container">
                        <div className="row mer-panel">
                            <div className="col-12 col-sm-10 col-lg-8 col-xl-7">
                                <div className="content">

                                    <p className="mer-color--gs-black">Kleenheat is selling its LPG distribution business, which distributes LPG cylinders and bulk LPG to residential, dealer networks, business and industrial customers across WA and the Northern Territory.</p>
                                    <p className="mer-color--gs-black">It is not selling its natural gas business which sells gas to homes and businesses in WA. Nothing is changing for our valued natural gas customers.</p>
                                    <p className="mer-color--gs-black">Kleenheat's LPG distribution business is being sold to another company called Supagas. The sale may take up to three months before it receives regulatory approval so it is business as usual for our LPG customers. A member of Kleenheat’s LPG sales team will reach out to customers shortly with further details.</p>

                                    <h3 className="mer-text--align-center">KLEENHEAT ANNOUNCEMENT FAQ</h3>
                                    <h4>Natural Gas Customers</h4>
                                    
                                    <h5 className="mb-0 mer-color--gs-black">Is Kleenheat being sold?</h5>
                                    <p className="my-0 mer-color--gs-black">No. Kleenheat isn’t being sold. Kleenheat is selling its LPG distribution business, which distributes LPG cylinders and bulk LPG to residential, dealer networks, business, and industrial customers across WA and the Northern Territory.</p>
                                    <p className="my-2 mer-color--gs-black">Kleenheat’s natural gas business – which sells natural gas to homes and businesses in WA – is not part of the sale. Nothing will change for you as a Kleenheat natural gas customer. We will continue to offer you great value along with friendly customer service.</p>
                                    <p className="mt-0 mer-color--gs-black">All invoices you receive from Kleenheat will continue to be payable to Kleenheat.</p>

                                    <h5 className="mb-0 mer-color--gs-black">Will my natural gas account be affected? Will my bills increase?</h5>
                                    <p className="my-0 mer-color--gs-black">No. The natural gas business for Kleenheat is not part of the sale and nothing changes for you. We value you as a customer and will continue to provide friendly and reliable customer service.</p>

                                    <h5 className="mb-0 mer-color--gs-black">Is Kleenheat being taken over by another company?</h5>
                                    <p className="my-0 mer-color--gs-black">No. Kleenheat remains a WA born and bred natural gas company. It remains part of the Wesfarmers Group and nothing changes.</p>

                                    <h4>LPG Customers</h4>
                                    
                                    <h5 className="mb-0 mer-color--gs-black">Is Kleenheat’s LPG business being sold?</h5>
                                    <p className="my-0 mer-color--gs-black">WesCEF has agreed to sell its LPG distribution businesses to Supagas.</p>
                                    <p className="my-2 mer-color--gs-black">There will be continuity in supply throughout the completion period. All invoices you receive from Kleenheat will be payable to Kleenheat.</p>
                                    <p className="mt-0 mer-color--gs-black">A member of Kleenheat’s sales team will reach out to you shortly to discuss further details.</p>

                                    <h5 className="mb-0 mer-color--gs-black">What does this mean for my LPG business with WesCEF/Kleenheat?</h5>
                                    <p className="my-0 mer-color--gs-black">There are no immediate changes for you as a customer. Kleenheat’s LPG business will continue to operate as normal and maintain our quality of service. This sale will take several months before the sale is officially complete.</p>
                                    <p className="my-2 mer-color--gs-black">Please continue to order LPG from us until further notice. Any invoice you receive from Kleenheat will continue to be payable to Kleenheat.</p>
                                    <p className="mt-0 mer-color--gs-black">A member of Kleenheat’s sales team will reach out to you shortly to discuss further details and address any concerns you may have.</p>

                                    <h5 className="mb-0 mer-color--gs-black">Will there be any changes to my current contracts or agreements with WesCEF/Kleenheat?</h5>
                                    <p className="my-0 mer-color--gs-black">There are no immediate changes to your contracts or agreements.</p>

                                    <h5 className="mb-0 mer-color--gs-black">Will there be any changes to billing or payment processes during the sale transaction?</h5>
                                    <p className="my-0 mer-color--gs-black">There are no changes to billing or payment processes, and you should continue to pay any outstanding invoices. You can continue to use the existing methods for payments. If there are any changes in the future, we will notify you in advance and provide assistance with any adjustments needed.</p>

                                    <h5 className="mb-0 mer-color--gs-black">Will there be any changes to the quality or reliability of the services provided?</h5>
                                    <p className="my-0 mer-color--gs-black">No. You’ll be dealing with the same great team, and we remain committed to delivering the same high-quality, reliable services that you have come to expect from us. The transition to new ownership will not impact our dedication to excellence and safety. We will continue to uphold our reputation for quality service throughout the transition process and beyond.</p>

                                    <h5 className="mb-0 mer-color--gs-black">I am currently ordering my cylinders or bulk gas by calling 13 21 80. Do I continue to do this, and when or if will the number change?</h5>
                                    <p className="my-0 mer-color--gs-black">You should continue to order your cylinders in the same way you have always done. We will be in touch with how to order from the new owner well in advance of the change.</p>

                                    <h5 className="mb-0 mer-color--gs-black">Does my price remain the same when I transfer to Supagas?</h5>
                                    <p className="my-0 mer-color--gs-black">At completion, the price you pay Kleenheat for your gas will transfer to Supagas. As for future bulk movements, they will remain unchanged and in accordance with your contract</p>
                                    <p className="my-2 mer-color--gs-black">What happens when the new owner takes over? Do I need to contact them, or will they contact me? Until the sale is officially complete, we don’t have this level of detail yet. Please be assured that you will be given plenty of notice of any changes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </LayoutPanel>

		    </LayoutMain>
            <Footer />
		</>
	)
}

export default BusinessUpdateFaq;